import config from '../config';

export const getFileContent = <T>(file: Blob): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function onReaderLoad(e: ProgressEvent<FileReader>) {
      if (e?.target?.result) {
        const result = JSON.parse(e.target.result as string);
        try {
          resolve(result);
        } catch (error) {
          reject('Error reading file content.');
        }
      } else {
        reject('Error reading file content.');
      }
    };

    reader.onerror = function onReaderError() {
      reject('Error reading file content.');
    };
  });
};

/**
 * Returns an array of paths to the target key within the object.
 * @param object - The object to search for the target key.
 * @param targetKey - The key to search for within the object.
 * @returns An array of paths to the target key within the object, or null if the key is not found.
 */
export function getPathsForKey(object: any, targetKey: string): (string | number)[] | null {
  /**
   * Recursively searches for the target key within the object.
   * @param obj - The object to search within.
   * @param key - The target key to search for.
   * @param currentPath - The current path to the key.
   * @returns An array of paths to the target key within the object, or null if the key is not found.
   */
  function findPath(
    obj: any,
    key: string,
    currentPath: (string | number)[] = []
  ): (string | number)[] | null {
    for (const prop of Object.keys(obj)) {
      const newPath = [...currentPath, prop];
      if (prop === key) {
        return newPath;
      }
      if (Array.isArray(obj[prop]) && !isNaN(Number(key))) {
        const index = Number(key);
        if (index >= 0 && index < obj[prop].length) {
          return [...newPath, index];
        }
      } else if (obj[prop] !== null && typeof obj[prop] === 'object') {
        const nestedPath = findPath(obj[prop], key, newPath);
        if (nestedPath) {
          return nestedPath;
        }
      }
    }
    return null;
  }

  return findPath(object, targetKey) || null;
}

export function addSrcToImage(jsonData: any) {
  for (let i = 0; i < jsonData.data.content.length; i++) {
    const contentItem = jsonData.data.content[i];
    if (contentItem.identifier === 'image') {
      if (contentItem.content.image.id !== null) {
        // Check if the "src" field is missing in the "content" object
        if (!contentItem.content.src) {
          // If it's missing, add a default "src" value
          contentItem.content.src = [
            {
              insert: `${config.feed_url}/api/filerepo/files/${contentItem?.content.image?.id}/data?quality=25`
            }
          ];
        }
      } else {
        jsonData.data.content.splice(i, 1);
      }
    }
  }

  return jsonData;
}

// Declare Google Analytics 'gtag' for TypeScript
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

// Utility function to track button click events
export const trackButtonClick = (buttonName: string, eventName: string) => {
  if (window.gtag) {
    window.gtag('event', eventName, {
      event_category: 'Button',
      event_label: buttonName
    });
  } else {
    console.warn('Google Analytics is not initialized.');
  }
};

//ReactGA.event({
//  category: 'Promotion',
// action: 'Displayed Promotional Widget',
//label: 'Homepage Thing',
//nonInteraction: true
//});
